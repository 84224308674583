export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      resource: 'Auth',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
 

  {
    path: '/login/:key',
    name: 'auth-register-confirm',
    props: true,
    component: () => import('@/views/pages/authentication/Confirm.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password/:key',
    props: true,
    name: 'auth-reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/general/Support.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  // {
  //   path: '/activity-log',
  //   name: 'activity-log',
  //   component: () => import('@/views/general/ActivityLog.vue'),
  //   meta: {
  //     resource: 'Auth',
  //   },
  // },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/general/FAQ.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/not-authorized',
    name: 'not-found',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },

  {
    path: '/logout',
    name: 'logout',
    props: true,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
]
