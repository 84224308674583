// const apiUrl = 'https://localhost:3000/';
// const apiUrl = 'https://192.168.1.10:3000/';
const apiUrl = "https://orkotechnologies.com:3000/";

export default {
  //////////////
  // Endpoints
  //////////////
  // Authentication
  loginEndpoint: `${apiUrl}user/login`,
  registerEndpoint: `${apiUrl}user/register`,
  registerCreateTenantEndpoint: `${apiUrl}tenant/`,
  registerTakePayment: `${apiUrl}tenant/subscriptions/new/`,
  resetUserPasswordRequest: `${apiUrl}user/password-reset-request`,
  resetUserPasswordLinkRequest: `${apiUrl}user/password-reset-verify?key=`,
  resetPasswordRequest: `${apiUrl}user/password-reset`,
  verifyAccountRequest: `${apiUrl}user/register-confirm?key=`,
  logoutEndpoint: `/logout`,
  //Orders
  getAllOrders: `${apiUrl}tenant/orders/`,
  getAllCustomers: `${apiUrl}tenant/customers/`,
  getCustomer: `${apiUrl}tenant/customer/`,
  getCoupons: `${apiUrl}tenant/coupons/`,
  getTransaction: `${apiUrl}tenant/orders/transaction/`,
  getActivityLog: `${apiUrl}activity-log/`,
  getCoupon: `${apiUrl}tenant/coupon/`,
  newCoupon: `${apiUrl}tenant/coupon/create/`,
  //Products
  listProducts: `${apiUrl}tenant/product/`,
  listCategories: `${apiUrl}tenant/categories/`,
  singleProduct: `${apiUrl}tenant/product/`,
  getProductAllergies: `${apiUrl}tenant/product-to-allergy/`,
  product_tags: `${apiUrl}tenant/product-to-tags/`,
  tags_product: `${apiUrl}tenant/tags-to-product/`,
  getAllergies: `${apiUrl}tenant/allergies/`,
  allergy: `${apiUrl}tenant/allergy/`,
  tags: `${apiUrl}tenant/tags/`,
  getProductAttributes: `${apiUrl}tenant/product-to-attribute/`,
  getAttributes: `${apiUrl}tenant/attributes/`,
  getVat: `${apiUrl}tenant/vat/`,
  getProductCategories: `${apiUrl}tenant/product-to-categories/`,
  patchCategories: `${apiUrl}tenant/category/`,
  pages: `${apiUrl}tenant/pages/`,
  getCategory: `${apiUrl}tenant/category/`,
  getAttributesProduct: `${apiUrl}tenant/attribute-to-product/`,
  getCategoriesProduct: `${apiUrl}tenant/categories-to-product/`,
  getAllergiesProduct: `${apiUrl}tenant/allergy-to-product/`,
  getReports: `${apiUrl}tenant_be/generateReport`,

  singleAttribute: `${apiUrl}tenant/attribute/`,
  //Tools
  justEatImporter: `${apiUrl}utility/justeat/`,
  // Settings
  settings: `${apiUrl}tenant/setting/`,
  //Billing
  subscriptions: `${apiUrl}tenant/subscriptions/`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: `Bearer`,

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: `accessToken`,
  storageRefreshTokenKeyName: `refreshToken`
};
