
import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'Tenant') return '/takeaway-dashboard' 
  if (userRole === 'User') return '/takeaway-dashboard' 
  return { name: 'auth-login' }
}

/*
  Check if user have permissions & assign them to user
*/
export const requireAuth = (to, from, next) => {

  if (getUserData() == null || useJwt.jwtConfig.storageTokenKeyName.length == 0) {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

    // Remove userData from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem('userOrders')
    localStorage.removeItem('userCustomers')
    localStorage.removeItem('userCoupons')
    localStorage.removeItem('userProducts')
    localStorage.removeItem('userCategories')
    localStorage.removeItem('userVatRates')
    localStorage.removeItem('userAllergies')
    localStorage.removeItem('userAttributes')
    localStorage.removeItem('userSettings')
    return false
  }


  return true;
}; 