import jwtDefaultConfig from './jwtDefaultConfig';

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${accessToken}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false;

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken);
              this.setRefreshToken(r.data.refreshToken);

              this.onAccessTokenFetched(r.data.accessToken);
            });
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  //Authentication Api Routes
  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }
  register_create_tenant(id, ...args) {
    return this.axiosIns.post(this.jwtConfig.registerCreateTenantEndpoint + id, ...args);
  }
  register_take_payment(...args) {
    return this.axiosIns.post(this.jwtConfig.registerTakePayment, ...args);
  }

  request_reset_password(...args) {
    return this.axiosIns.post(this.jwtConfig.resetUserPasswordRequest, ...args);
  }
  request_reset_password_link(key, ...args) {
    return this.axiosIns.get(this.jwtConfig.resetUserPasswordLinkRequest + key, ...args);
  }
  verify_account(key, ...args) {
    return this.axiosIns.get(this.jwtConfig.verifyAccountRequest + key, ...args);
  }
  reset_password(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPasswordRequest, ...args);
  }

  // Tenant API Routes
  //Shop
  get_all_orders() {
    return this.axiosIns.get(this.jwtConfig.getAllOrders);
  }
  delete_order(id) {
    return this.axiosIns.delete(this.jwtConfig.getAllOrders + id);
  }
  get_order(id) {
    return this.axiosIns.get(this.jwtConfig.getAllOrders + id);
  }
  refund(id) {
    return this.axiosIns.patch(this.jwtConfig.getAllOrders + id);
  }
  get_order_transaction(id) {
    return this.axiosIns.get(this.jwtConfig.getTransaction + id);
  }
  get_activity_log() {
    return this.axiosIns.get(this.jwtConfig.getActivityLog);
  }
  get_all_customers() {
    return this.axiosIns.get(this.jwtConfig.getAllCustomers);
  }
  get_customer(id) {
    return this.axiosIns.get(this.jwtConfig.getCustomer + id);
  }
  patch_customer(id, ...args) {
    return this.axiosIns.patch(this.jwtConfig.getCustomer + id, ...args);
  }
  get_coupons() {
    return this.axiosIns.get(this.jwtConfig.getCoupons);
  }
  update_coupon(id, ...args) {
    return this.axiosIns.patch(this.jwtConfig.getCoupon + id, ...args);
  }
  new_coupon(...args) {
    return this.axiosIns.post(this.jwtConfig.newCoupon, ...args);
  }

  // Products

  list_products() {
    return this.axiosIns.get(this.jwtConfig.listProducts);
  }
  list_categories() {
    return this.axiosIns.get(this.jwtConfig.listCategories);
  }
  delete_product(id) {
    return this.axiosIns.delete(this.jwtConfig.singleProduct + id);
  }
  get_product(id) {
    return this.axiosIns.get(this.jwtConfig.singleProduct + id);
  }
  get_product_allergies(id) {
    return this.axiosIns.get(this.jwtConfig.getProductAllergies + id);
  }
  get_product_tags(id) {
    return this.axiosIns.get(this.jwtConfig.getProductTags + id);
  }
  get_allergies() {
    return this.axiosIns.get(this.jwtConfig.getAllergies);
  }
  get_tags() {
    return this.axiosIns.get(this.jwtConfig.tags);
  }
  patch_allergy(id, ...args) {
    return this.axiosIns.patch(this.jwtConfig.allergy + id, ...args);
  }
  patch_tag(id, ...args) {
    return this.axiosIns.patch(this.jwtConfig.tags + id, ...args);
  }
  get_attributes() {
    return this.axiosIns.get(this.jwtConfig.getAttributes);
  }
  get_product_attributes(id) {
    return this.axiosIns.get(this.jwtConfig.getProductAttributes + id);
  }
  get_vat() {
    return this.axiosIns.get(this.jwtConfig.getVat);
  }
  patch_vat(id, ...args) {
    return this.axiosIns.patch(this.jwtConfig.getVat + id, ...args);
  }
  post_vat(id, ...args) {
    return this.axiosIns.post(this.jwtConfig.getVat + id, ...args);
  }
  post_tags(id, ...args) {
    return this.axiosIns.post(this.jwtConfig.tags + id, ...args);
  }
  delete_vat(id) {
    return this.axiosIns.delete(this.jwtConfig.getVat + id);
  }
  delete_product_categories(id) {
    return this.axiosIns.delete(this.jwtConfig.getProductCategories + id);
  }
  delete_product_allergy(id) {
    return this.axiosIns.delete(this.jwtConfig.getProductAllergies + id);
  }
  delete_product_attribute(id) {
    return this.axiosIns.delete(this.jwtConfig.getProductAttributes + id);
  }

  delete_categories_product(id) {
    return this.axiosIns.delete(this.jwtConfig.getCategoriesProduct + id);
  }
  delete_allergy_product(id) {
    return this.axiosIns.delete(this.jwtConfig.getAllergiesProduct + id);
  }
  delete_tag_product(id) {
    return this.axiosIns.delete(this.jwtConfig.tags_product + id);
  }
  delete_attribute_product(id) {
    return this.axiosIns.delete(this.jwtConfig.getAttributesProduct + id);
  }
  patch_product(id, ...args) {
    return this.axiosIns.patch(this.jwtConfig.singleProduct + id, ...args);
  }
  post_product_allergy(id, ...args) {
    return this.axiosIns.post(this.jwtConfig.getProductAllergies + id, ...args);
  }
  post_product_attributes(id, ...args) {
    return this.axiosIns.post(this.jwtConfig.getProductAttributes + id, ...args);
  }
  post_product_categories(id, ...args) {
    return this.axiosIns.post(this.jwtConfig.getProductCategories + id, ...args);
  }
  post_product_tag(id, ...args) {
    return this.axiosIns.post(this.jwtConfig.product_tags + id, ...args);
  }
  post_product_tag(id, ...args) {
    return this.axiosIns.post(this.jwtConfig.product_tags + id, ...args);
  }
  post_product(id, ...args) {
    return this.axiosIns.post(this.jwtConfig.singleProduct + id, ...args);
  }
  get_page(id) {
    return this.axiosIns.get(this.jwtConfig.pages + id);
  }
  patch_page(id, ...args) {
    return this.axiosIns.patch(this.jwtConfig.pages + id, ...args);
  }
  patch_category(id, ...args) {
    return this.axiosIns.patch(this.jwtConfig.patchCategories + id, ...args);
  }
  get_category(id) {
    return this.axiosIns.get(this.jwtConfig.patchCategories + id);
  }
  post_category(id, ...args) {
    return this.axiosIns.post(this.jwtConfig.patchCategories + id, ...args);
  }
  patch_attribute(id, ...args) {
    return this.axiosIns.patch(this.jwtConfig.singleAttribute + id, ...args);
  }
  post_attribute(id, ...args) {
    return this.axiosIns.post(this.jwtConfig.singleAttribute + id, ...args);
  }
  delete_attribute(id) {
    return this.axiosIns.delete(this.jwtConfig.singleAttribute + id);
  }
  delete_all_products(id) {
    return this.axiosIns.delete(this.jwtConfig.listProducts + id);
  }
  delete_all_categories(id) {
    return this.axiosIns.delete(this.jwtConfig.getCategory + id);
  }
  justeat_importer(...args) {
    return this.axiosIns.post(this.jwtConfig.justEatImporter, ...args);
  }
  getReports(...args) {
    return this.axiosIns.post(this.jwtConfig.getReports, ...args);
  }
  get_settings() {
    return this.axiosIns.get(this.jwtConfig.settings);
  }
  patch_settings(...args) {
    return this.axiosIns.patch(this.jwtConfig.settings, ...args);
  }
  //BILLING
  subscriptions(id, ...args) {
    return this.axiosIns.get(this.jwtConfig.subscriptions + id, ...args);
  }
}
