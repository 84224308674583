import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({ 
  timeout: 60000, 
})

Vue.prototype.$http = axiosIns

export default axiosIns
