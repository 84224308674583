export default [
  {
    path: '/takeaway-dashboard',
    name: 'takeaway-dashboard',
    component: () => import('@/views/takeaway/user/Dashboard.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/shop/orders',
    name: 'takeaway-shop-orders',
    component: () => import('@/views/takeaway/user/Orders.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/shop/orders/:id',
    name: 'takeaway-shop-orders-single',
    props: true,
    component: () => import('@/views/takeaway/user/OrderSingle.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/shop/customers',
    name: 'takeaway-shop-customers',
    component: () => import('@/views/takeaway/user/Customers.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/shop/customers/:id',
    name: 'takeaway-shop-customers-single',
    props: true,
    component: () => import('@/views/takeaway/user/CustomerSingle.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/shop/coupons',
    name: 'takeaway-shop-coupons',
    component: () => import('@/views/takeaway/user/Coupons.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products',
    name: 'takeaway-products-all',
    component: () => import('@/views/takeaway/user/Products.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products/new',
    name: 'takeaway-products-new-product',
    component: () => import('@/views/takeaway/user/NewProduct.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products/categories',
    name: 'takeaway-products-categories',
    component: () => import('@/views/takeaway/user/Categories.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products/categories/new',
    name: 'takeaway-products-categories-new',
    component: () => import('@/views/takeaway/user/NewCategory.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products/categories/:id',
    name: 'takeaway-products-categories-single',
    props: true,
    component: () => import('@/views/takeaway/user/EditCategory.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products/attributes',
    name: 'takeaway-products-attributes',
    component: () => import('@/views/takeaway/user/Attributes.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products/attributes/new',
    name: 'takeaway-products-attributes-new',
    component: () => import('@/views/takeaway/user/NewAttributes.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products/attributes/:id',
    name: 'takeaway-products-attributes-new',
    component: () => import('@/views/takeaway/user/EditAttributes.vue'),
    props: true,
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products/allergies',
    name: 'takeaway-products-allergies',
    component: () => import('@/views/takeaway/user/Allergies.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products/tags',
    name: 'takeaway-products-tags',
    component: () => import('@/views/takeaway/user/Tags.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products/vat',
    name: 'vat-rates',
    component: () => import('@/views/takeaway/user/VatRates.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/products/:id',
    name: 'takeaway-products-edit',
    props: true,
    component: () => import('@/views/takeaway/user/EditProduct.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/pages/privacy',
    name: 'takeaway-pages-privacy',
    component: () => import('@/views/takeaway/user/EditPrivacyPage.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/pages/terms',
    name: 'takeaway-pages-terms',
    component: () => import('@/views/takeaway/user/EditTermsPage.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/pages/about',
    name: 'takeaway-pages-about',
    component: () => import('@/views/takeaway/user/EditAboutPage.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/reporting/transactions',
    name: 'takeaway-reporting-transactions',
    component: () => import('@/views/takeaway/user/Transactions.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/reporting/transactions/:id',
    name: 'takeaway-reporting-transactions-single',
    props: true,
    component: () => import('@/views/takeaway/user/TransactionSingle.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/reporting/reports',
    name: 'takeaway-reporting-reports',
    component: () => import('@/views/takeaway/user/Reports.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/tools/just-eat-importer',
    name: 'takeaway-tools-just-eat-importer',
    component: () => import('@/views/takeaway/user/JustEatImporter.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  },
  {
    path: '/takeaway/settings',
    name: 'takeaway-settings',
    component: () => import('@/views/takeaway/user/Settings.vue'),
    meta: {
      permission: 'takeaway',
      resource: 'Auth'
    }
  }
];
