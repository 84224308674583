import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, requireAuth } from '@/auth/utils'
import takeaway from './routes/takeaway'



import useJwt from '@/auth/jwt/useJwt'


//Real Routes
import general from './routes/general'

Vue.use(VueRouter)

const userData = getUserData();
const userPermissions = userData ? userData.permissions : [];


const routes = [
  { path: '/' },
  ...general,
];

  const hasTakeawayPermission = userPermissions.some(permission => permission.permission_key === 'takeaway');

  // if (hasTakeawayPermission) {
    routes.push(...takeaway);
  // }



  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
      return { x: 0, y: 0 }
    },
    routes 
  })
const unsetUser = () => {

  // Remove Auth Keys used to communicate with API
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
  localStorage.removeItem('userOrders')
  localStorage.removeItem('userCustomers')
  localStorage.removeItem('userCategories')
  localStorage.removeItem('userVatRates')
  localStorage.removeItem('userCoupons')
  localStorage.removeItem('userProducts')
  localStorage.removeItem('userAllergies')
  localStorage.removeItem('userAttributes')
  localStorage.removeItem('userSettings')

  // Remove userData from localStorage
  localStorage.removeItem('userData')
}


router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect to login if not logged in
  if (!isLoggedIn) {
    unsetUser()
    return next();
  } else {
// console.log(to)
// console.log(from)
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn) {
    const userData = getUserData();
    unsetUser();
    router.push(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  // Redirect to takeaway dashboard if the current route is "/"
  if (to.path === '/') {
    return next('/takeaway-dashboard')
  }
  if (typeof to.meta.permission != "undefined") {
    for (let x = 0; x < JSON.parse(localStorage.getItem('userData')).permissions.length; x++) {
      if (JSON.parse(localStorage.getItem('userData')).permissions[x].permission_key == to.meta.permission) {
        return next();
      }
    }
    return router.push('/not-authorized')
  }
  return next();
})

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  const isLoggedIn = isUserLoggedIn();
  // Redirect to login if not logged in
  if (!isLoggedIn) {
    if (router.currentRoute.meta.resource != 'Auth') {
      unsetUser()
      return router.push('/login')
    }
  }

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
